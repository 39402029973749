<template>
    <div class="d-flex overflow-hidden justify-content-center">
        <!-- background -->
        <div
            class="cover-container position-relative w-100 gap-2 my-auto px-4 d-flex flex-column justify-content-center align-items-center d-none d-md-block">
            <div class="image-container position-relative">
                <img class="vvv-logo img-fluid" src="../../assets/VVV/images/Login/vvv-logo-white-top-left-corner.png"
                    alt="VVV Logo">
                <img class="caption img-fluid position-absolute top-0 start-0"
                    src="../../assets/VVV/images/Login/veryviral-spotlight-text.png" alt="Caption">
                <img class="thumbnail img-fluid position-absolute top-0 start-0"
                    src="../../assets/VVV/images/Login/video-thumbnail-player.png" alt="Video thumbnail">
                <img class="play-button img-fluid position-absolute top-0 start-0"
                    src="../../assets/VVV/images/Login/video-playbutton-to-hyperlink-to-tiktokURL.png"
                    alt="Video play button image">
                <img class="watch-now img-fluid position-absolute top-0 start-0"
                    src="../../assets/VVV/images/Login/watch-now-usethisclip-creator.png" alt="Watch now image">
            </div>
        </div>
        <!-- form -->
        <div
            class="login-container position-relative w-100 gap-2 my-auto px-4 d-flex flex-column justify-content-center align-items-center">
            <!-- logo -->
            <div>
                <img class="w-50 d-flex m-auto" src="../../assets/VVV/images/Login/vvv.png" alt="VVV Logo">
            </div>
            <!-- form -->
            <div class="form-container" v-if="isSignIn">
                <form class="shadow rounded-7">
                    <!-- title -->
                    <div class="d-flex gap-2 justify-content-center text-black">
                        <h1 class="fw-bold h2">Distributor</h1>
                        <h2>Login</h2>
                    </div>
                    <!-- details -->
                    <h6 class="text-center text-black-50">Access over 1-million of the Internet's best videos</h6>
                    <!-- input fields -->
                    <div class="px-3">
                        <MDBInput class="my-3" label="Email" type="email" v-model="email" required />
                        <MDBInput label="Password" type="password" v-model="password" required />
                    </div>
                    <!-- forgot password -->
                    <div class="text-end px-3" @click="toggleForgotPassword">
                        <span class="forgot-password text-black">Forgot Password?</span>
                    </div>
                    <!-- login button -->
                    <button @click.prevent="login" data-mdb-ripple-init type="submit" class="btn btn-block text-white mt-3">Log
                        in</button>
                </form>
                <!-- sign up link -->
                <div class="d-flex align-items-center justify-content-center my-4">
                    <h6 class="m-0 me-2">Don't have an account?</h6>
                    <a href="https://www.veryviralvisuals.com/esign" target="_blank" class="fw-bold text-black">Sign
                        up</a>
                </div>
            </div>
            <!-- terms of service -->
            <p class="terms-of-service text-center text-wrap">By continuing to use our service, you agree to our <a
                    href="https://www.veryviralvisuals.com/vvv-pp-tos" target="_blank" class="terms-link">Terms of Service</a>
                which may be periodically updated.</p>
            <!-- reset passowrd -->
            <div class="form-container" v-if="isForgotPassword">
                <!-- email sent -->
                <form class="shadow rounded-7" v-if="isSent">
                    <div class="d-flex gap-2 justify-content-center text-black">
                        <h1 class="h2">Email has been sent.</h1>
                    </div>
                    <h6 class="text-center text-black-50">Please follow the steps mentioned in the email to reset your
                        password.</h6>
                </form>
                <!-- enter email -->
                <form class="shadow rounded-7" v-else>
                    <div class="d-flex gap-2 justify-content-center text-black">
                        <h1 class="fw-bold h2">Forgot</h1>
                        <h2>Password</h2>
                    </div>
                    <h6 class="text-center text-black-50">We'll email you a link to reset your password.</h6>
                    <div class="px-3">
                        <MDBInput class="my-3" label="Email" type="email" v-model="emailForget"
                            @keypress.enter="forgotPassword" required />
                    </div>
                    <button @click="forgotPassword" data-mdb-ripple-init type="submit"
                        class="btn btn-block text-white mt-3">Send</button>
                </form>
                <!-- back to login -->
                <div class="d-flex align-items-center justify-content-center my-4">
                    <h6 class="m-0 me-2">Back to</h6>
                    <span @click="toggleSignIn" target="_blank" class="fw-bold text-black">Log in</span>
                </div>
            </div>
        </div>
    </div>
    <!-- error message -->
    <MDBToast v-model="toastError" id="basic-danger-example" autohide position="top-right" appendToBody stacking
        width="350px" color="danger" text="white" icon="fas fa-info-circle fa-lg me-2">
        <template #title>Error</template>
        {{ toastMessage }}
    </MDBToast>
</template>

<script setup>
import { MDBInput, MDBToast } from 'mdb-vue-ui-kit';
import '../../assets/Sailec-Font/fonts.css'
import { ref, onMounted } from "vue";
import { useTitle } from "@vueuse/core";
import { useRouter } from "vue-router";
import axios from "axios";

useTitle("Login | Very Viral Visuals");

const router = useRouter();

onMounted(() => {
    useTitle("Login | Very Viral Visuals");
});

const email = ref("");
const password = ref("");
const toastError = ref(false);
const toastMessage = ref("");

const login = () => {
    axios
        .post("api/users/login", {
            email: email.value,
            password: password.value,
        })
        .then(function (response) {
            if (response.status === undefined) {
                if (response.response.status == 404) {
                    toastError.value = true;
                    toastMessage.value = "Incorrect email or password.";
                    password.value = "";
                } else {
                    toastError.value = true;
                    toastMessage.value = "An error occurred during request.";
                    password.value = "";
                }
            } else {
                router.push("/Dashboard");
            }
        })
        .catch(function (error) {
            if (error.response.status == 404) {
                toastError.value = true;
                toastMessage.value = "Incorrect email or password.";
                password.value = "";
            } else {
                toastError.value = true;
                toastMessage.value = "An error occurred during request.";
                password.value = "";
            }
        });
};

const isSignIn = ref(true);
const isForgotPassword = ref(false);
const toggleForgotPassword = () => {
    isSent.value = false;
    isForgotPassword.value = true;
    isSignIn.value = false;
    useTitle("Reset Password | Very Viral Visuals");
};
const toggleSignIn = () => {
    isSignIn.value = true;
    isForgotPassword.value = false;
    useTitle("Login | Very Viral Visuals");
};

const emailForget = ref("");
const isSent = ref(false);
const forgotPassword = () => {
    axios
        .post("api/users/forgotPassword", {
            email: emailForget.value,
        })
        .then(function (response) {
            if (response.status === undefined) {
                if (response.response.status == 404) {
                    toastError.value = true;
                    toastMessage.value = "Email does not exist.";
                    emailForget.value = "";
                } else {
                    toastError.value = true;
                    toastMessage.value = "An error occurred during request.";
                    emailForget.value = "";
                }
            } else {
                isSent.value = true;
            }
        })
        .catch(function (error) {
            if (error.response.status == 404) {
                toastError.value = true;
                toastMessage.value = "Email does not exist.";
                emailForget.value = "";
            } else {
                toastError.value = true;
                toastMessage.value = "An error occurred during request.";
                emailForget.value = "";
            }
        });
};
</script>

<style lang="scss" scoped>
.cover-container {
    background-image: url("../../assets/VVV/images/Login/blue-gradient-bg.png");
    background-size: cover;
    width: 100%;
    height: 100vh;

    .vvv-logo {
        width: 13.5%;
        margin: 3%;
    }

    .image-container {
        width: 100%;
        height: 100%;
    }

    .caption,
    .thumbnail,
    .play-button,
    .watch-now {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .caption {
        z-index: 2;
    }

    .thumbnail {
        justify-items: center;
        z-index: 3;
    }

    .play-button,
    .watch-now {
        z-index: 3;
    }
}

.login-container {
    height: 100vh;

    .form-container {
        padding: 30px 60px 10px 60px;
    }

    form {
        padding: 45px;
    }

    h1 {
        font-family: "Sailec Bold", sans-serif;
    }

    h2,
    h6 {
        font-family: "Sailec Medium", sans-serif;
    }

    .forgot-password {
        font-family: "Sailec Medium", sans-serif;
        font-size: 12px;
        cursor: pointer;
    }

    .btn {
        background-image: linear-gradient(to right, #5C40EA, #0071FF);
    }

    .btn:hover {
        color: white;
    }

    span {
        cursor: pointer;
    }

    .terms-of-service {
        position: fixed;
        bottom: 0;
        text-align: center;
        font-size: 11px;
    }

    .terms-link {
        color: #1F87FC;
        cursor: pointer;
    }
}
</style>
